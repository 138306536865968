import React, { useEffect, useState } from "react";
import classes from "./Wishlist.module.css";
import { Link } from "react-router-dom";
import makeHttpRequest from "../../utils/api";
import { useLanguage } from "../../utils/LanguageContext";
import "../../main.css";
let style = {};
const isMobile = window.innerWidth <= 767;
if (isMobile) {
  style = {
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
} else {
  style = {
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: "0px 4px 32px 0px rgba(26, 26, 26, 0.10)",
    borderRadius: "16px",
    border: "none",
    outline: "none",
  };
}

function Wishlist() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const {  translate } = useLanguage();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "user/wishlist");
      console.log(res.data);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  function generateSlug(inputString) {
    if (inputString) {
      const slug = inputString.toLowerCase().replace(/[^a-z0-9]+/g, "-");
      return slug.replace(/^-+|-+$/g, "");
    }
  }

  return (
    <>
      <div className="container mt-2">
        <div>
          <div className="content-bg text-center">
            <div className={classes["reviews-viewallWrapper"]}>
              <h2>{translate("My Wishlist")}</h2>
            </div>
            <div className="table-responsive" style={{ borderRadius: "10px" }}>
              <div className={classes["table-wrapper"]}>
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className={classes["reviews-table-th"]}>
                    <th>{translate("#")}</th>
                    <th>{translate("List")}</th>
                    <th>{translate("View")}</th>
                  </thead>
                  <tbody>
                    {data?.wishlists?.map((wish, i) => (
                      <tr>
                        <td>{wish.id}</td>
                        <td>
                          {wish?.property_id
                            ? wish?.properties?.name
                            : wish?.boats?.name}
                        </td>
                        <td>
                          {wish?.property_id ? (
                            <Link
                              style={{
                                textDecoration: "none",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`/accomodation/${
                                wish?.properties?.id
                              }/${generateSlug(
                                wish?.properties?.name
                              )}/details`}
                            >
                              {translate("View Detail")}
                            </Link>
                          ) : (
                            <Link
                              style={{
                                textDecoration: "none",
                              }}
                              target="_blank"
                              rel="noopener noreferrer"
                              to={`/boat/${wish?.boats?.id}/${generateSlug(
                                wish?.boats?.name
                              )}/details`}
                            >
                              {translate("View Detail")}
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}

export default Wishlist;
