// AddressAutocomplete.js
import React, { useState } from "react";
import AutoComplete from "react-google-autocomplete";

const AddressAutocomplete = ({
  apiKey,
  onPlaceChanged,
  AddressSet,
  placeHolder,
  className,
  currentValue,
}) => {
  const [place, setPlace] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [countryPhoneCode, setCountryPhoneCode] = useState("");

  const handlePlaceChanged = (place) => {
    setPlace(place);
    setAddress(place.formatted_address);
    AddressSet(place?.formatted_address);
    const countryAddressComponent = place.address_components?.find(
      (addressComponent) => addressComponent.types[0] === "country"
    );
    if (countryAddressComponent) {
      setCountryCode(countryAddressComponent.short_name);
      // getCountryPhoneCode(countryAddressComponent.short_name);
    }
    // if (onPlaceChanged) {
    onPlaceChanged(place);
    // }
  };

  return (
    <AutoComplete
      apiKey={apiKey}
      // options={{ types: ['establishment'], fields: ['name'] }}
      // options={{ types: ["establishment"] }}
      options={{
        types: ["address"],
        componentRestrictions: { country: "IT" },
      }}
      onPlaceSelected={handlePlaceChanged}
      placeholder={placeHolder}
      className={className}
      // value={currentValue}
    />
  );
};

export default AddressAutocomplete;
