import React, { useState } from "react";
import classes from "./UserProfile.module.css";
import { useLanguage } from "../../utils/LanguageContext";

function UserProfile() {
  // Personal Info

  const [fullname, setfullname] = useState("Jane Cooper");
  const [email, setemail] = useState("jane@example.com");
  const [phonenumber, setphonenumber] = useState("+123456789");
  const [address, setaddress] = useState(
    "8502 Preston Rd. Inglewood, Maine 98380"
  );
  const [governmentId, setgovernmentId] = useState("1234567890");
  const [emergencyContact, setemergencyContact] = useState("+21304560789");
  const [textarea, settextarea] = useState("Write a message");
  const { translate } = useLanguage();

  return (
    <>
      {/* <Navbar /> */}
      <div className="container mt-4">
        <div className="row">
          <div className={`col-lg-3 col-md-3 col-12 ${classes.profileCard}`}>
            <div className="container d-flex">
              <div className={`${classes["profile-container"]} mt-2`}>
                <img
                  src="/assets/icons/profilePic.png"
                  alt="userProfilePic"
                  className={classes["profile-pic"]}
                />
                <span className={classes["edit-icon"]}>
                  <img
                    src="/assets/icons/profileEdit.png"
                    alt="userProfileEdit"
                  />
                </span>
              </div>
              <div className={`container ${classes["profile_info"]} mt-3`}>
                <b style={{ fontFamily: "Montserrat" }}>David Smith</b>
                <p
                  style={{ fontFamily: "Montserrat" }}
                  className={classes["p-tag-color"]}
                >
                  abcdef@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12">
            <div className="d-flex">
              <div className={`container ${classes.profileCard} ml-4`}>
                <div className="container d-flex">
                  <div className={`${classes.ticketCards} mt-2`}>
                    <img
                      src="/assets/icons/totalTicket.png"
                      alt="totalTicketPic"
                      className={classes["profile-pic"]}
                    />
                  </div>
                  <div className={`container ${classes["ticket_info"]} mt-4`}>
                    <b>
                      <span style={{ fontFamily: "Montserrat" }}>02</span>
                    </b>
                    <p
                      className={`${classes["p-tag-color"]} mb-0 mt-1`}
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Total Tickets
                    </p>
                  </div>
                </div>
              </div>
              <div className={`container ${classes["profileCard"]} mx-3`}>
                <div className="container d-flex">
                  <div className={`${classes.ticketCards} mt-2`}>
                    <img
                      src="/assets/icons/totalBookedTicket.png"
                      alt="totalBookedTicketPic"
                      className={classes["profile-pic"]}
                    />
                  </div>
                  <div className={`container ${classes.ticket_info} mt-4`}>
                    <b>
                      <span style={{ fontFamily: "Montserrat" }}>02</span>
                    </b>
                    <p
                      className={`${classes["p-tag-color"]} mb-0 mt-1`}
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Total Booked
                    </p>
                  </div>
                </div>
              </div>
              <div className={`container ${classes.profileCard}`}>
                <div className="container d-flex">
                  <div className={`${classes.ticketCards} mt-2`}>
                    <img
                      src="/assets/icons/totalPendingReviews.png"
                      alt="totalPendingReviewsPic"
                      className={classes["profile-pic"]}
                    />
                  </div>
                  <div className={`container ${classes.ticket_info} mt-4`}>
                    <b>
                      <span style={{ fontFamily: "Montserrat" }}>02</span>
                    </b>
                    <p
                      className={`${classes["p-tag-color"]} mb-0 mt-1`}
                      style={{ fontFamily: "Montserrat" }}
                    >
                      Pending Reviews
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={classes["supportTicket-bg"]}>
            {/* <div className="supportTicket-btnWrapper">
                  <h2> Profile Edit</h2>
                </div> */}
            <div className="table-responsive" style={{ borderRadius: "10px" }}>
              <div className={classes["supportTicket-wrapper"]}>
                <form>
                  <p
                    className="h1 ml-4 my-3"
                    style={{ fontFamily: "Montserrat" }}
                  >
                    {translate("Personal Info")}
                  </p>
                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="fullName"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Full name")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="text"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="fullName"
                        onChange={(e) => {
                          setfullname(e.target.value);
                        }}
                        style={{ fontFamily: "Montserrat" }}
                        value={fullname}
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="email"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Email address")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="email"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="email"
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                        value={email}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="phoneNumber"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Phone Number")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="text"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="phoneNumber"
                        onChange={(e) => {
                          setphonenumber(e.target.value);
                        }}
                        value={phonenumber}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="address"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Address")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="text"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="address"
                        onChange={(e) => {
                          setaddress(e.target.value);
                        }}
                        value={address}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </div>
                  </div>

                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="governmentId"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Government Id")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="text"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="governmentId"
                        onChange={(e) => {
                          setgovernmentId(e.target.value);
                        }}
                        value={governmentId}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </div>
                  </div>
                  <div className="form-group col">
                    <b>
                      <label
                        htmlFor="emergencyContact"
                        className="col-sm-12 col-form-label"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        {translate("Emergency Contact")}
                      </label>
                    </b>
                    <br />
                    <div className="col-sm-12 d-flex justify-content-between m-0">
                      <input
                        type="text"
                        className={`${classes["form-control-plaintext"]} pl-4`}
                        id="emergencyContact"
                        onChange={(e) => {
                          setemergencyContact(e.target.value);
                        }}
                        value={emergencyContact}
                        style={{ fontFamily: "Montserrat" }}
                      />
                    </div>
                    <div className="form-group col">
                      <b>
                        <label
                          htmlFor="emergencyContact"
                          className="col-sm-12 col-form-label"
                          style={{ fontFamily: "Montserrat" }}
                        >
                          {translate("About me")}
                        </label>
                      </b>
                      <br />
                      <div className="col-sm-12 d-flex justify-content-between m-0">
                        <textarea
                          rows={4}
                          cols={50}
                          type="text"
                          className={`${classes["form-control-plaintext"]} pl-4`}
                          id="textarea"
                          onChange={(e) => {
                            settextarea(e.target.value);
                          }}
                          value={textarea}
                          style={{ fontFamily: "Montserrat" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
