import React, { useEffect, useState } from "react";
import { BsFillStarFill } from "react-icons/bs";
import { showAmount } from "../../utils/showAmount";
import makeHttpRequest from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../utils/LanguageContext";
import { title } from "../../utils/helpers";
import Toaster from "../Toaster";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField } from "@mui/material";

function BoatDetailMobileLeftbar() {
  const navigate = useNavigate();
  const { id, slug } = useParams();
  const [checkInDate, setCheckInDate] = useState("");
  console.log("checkInDate", checkInDate);
  const [checkOutDate, setCheckOutDate] = useState("");
  console.log("checkOutDate", checkOutDate);

  const { langData, fetchLangData, translate } = useLanguage();

  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(0);
  const [platform_fee, setPlatformFee] = useState(0);

  const handleCheckInChange = (event) => {
    setCheckInDate(event.target.value);
    calculateDateDifference(event.target.value, checkOutDate);
  };

  const handleCheckOutChange = (event) => {
    setCheckOutDate(event.target.value);
    calculateDateDifference(checkInDate, event.target.value);
  };

  const calculateDateDifference = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const checkInDateObj = new Date(checkIn);
      const checkOutDateObj = new Date(checkOut);

      if (checkOutDateObj < checkInDateObj) {
        alert("Check-out date must be later than check-in date");
        setDays(0);
        setCheckOutDate(checkIn + 1);
      } else {
        const differenceInMilliseconds = checkOutDateObj - checkInDateObj;
        let differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
        // alert(checkOutDateObj == checkInDateObj)
        if (
          checkOutDateObj.getFullYear() === checkInDateObj.getFullYear() &&
          checkOutDateObj.getMonth() === checkInDateObj.getMonth() &&
          checkOutDateObj.getDate() === checkInDateObj.getDate()
        ) {
          setDays((differenceInDays = 1));
        } else {
          setDays(differenceInDays + 1);
        }
      }
    } else {
      setDays(0);
    }
  };

  const handleInquiry = async (req) => {
    if (checkInDate && checkOutDate) {
      try {
        setLoading(true);
        if (req === "inquiry") {
          const res = await makeHttpRequest("POST", `user/boat/booking`, {
            boat_list: id,
            start: checkInDate,
            end: checkOutDate,
            days: days,
          });
          Toaster("success", res.message);
        } else {
          const res = await makeHttpRequest("POST", `user/chat/message/store`, {
            slug,
            id,
            type: "item",
            item: "boat",
            start: checkInDate,
            end: checkOutDate,
            days: days,
            url: window.location.href,
          });
          if (res.success) {
            navigate(`/chat/boat/${id}/${slug}`);
          } else {
            Toaster("error", "Something went wrong");
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error is:", error);
        setLoading(false);
      }
    } else {
      Toaster("error", "Select Check in and Check out date");
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", `boat/${id}/${slug}`);
      if (res.success) {
        setDetails(res.data);
        console.log(details?.boat?.owner_review);
        document.title = title(res?.data?.boat?.name);
        setLoading(false);
      } else if (!res.success) {
        navigate("/boat");
      } else {
        navigate("/boat");
      }
    } catch (error) {
      console.error("Error is:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [slug]);

  return (
    <div>
      <div className="boatDetail-right-bg">
        <div>
          <div className="boatDetail-reviews mb-3">
            <div className="d-flex justify-content-between align-items-cent">
              <div>
                <BsFillStarFill
                  style={{
                    color: "#8BC53E",
                    marginRight: "7px",
                    fontSize: "15px",
                  }}
                />
                <span className="reviews">
                  <a href="#">
                    {showAmount(details?.boat?.rating)} ({details?.boat?.review}
                    )
                  </a>
                </span>
              </div>
              <div className="boat-total-price">
                <h3>
                  {details?.curr_sym} {showAmount(details?.boat?.price)}{" "}
                  <span>{translate(details?.boat?.label)}</span>
                </h3>
              </div>
            </div>
          </div>

          <div className="row">
            <h4 className="fw-bold fs-6">Check In / Check Out</h4>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <input
                type="datetime-local"
                placeholder={translate("Check In")}
                value={checkInDate}
                onChange={handleCheckInChange}
                min={new Date().toISOString().split("T")[0]}
                id="check-in"
                className="form-control date-input"
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <input
                type="datetime-local"
                placeholder={translate("Check Out")}
                value={checkOutDate}
                onChange={handleCheckOutChange}
                min={(function () {
                  const tomorrow = new Date();
                  tomorrow.setDate(tomorrow.getDate() + 1);
                  return tomorrow.toISOString().split("T")[0];
                })()}
                id="check-out"
                className="form-control date-input"
              />
              {/* <input
                id="check-out"
                type="datetime-local"
                placeholder={translate("Check Out")}
                value={checkOutDate}
                onChange={handleCheckOutChange}
                min={(function () {
                  const tomorrow = new Date();
                  tomorrow.setDate(tomorrow.getDate() + 1);
                  return tomorrow.toISOString().split("T")[0];
                })()}
                className="form-control date-input"
              /> */}
            </div>
            {checkInDate && checkOutDate && (
              <div className="accomodation-content-para">
                <p className="mt-2">
                  Selected Dates:{" "}
                  {checkInDate
                    ? new Date(checkInDate).toLocaleDateString()
                    : "N/A"}{" "}
                  -{" "}
                  {checkOutDate
                    ? new Date(checkOutDate).toLocaleDateString()
                    : "N/A"}
                </p>
              </div>
            )}
          </div>

          <div className="boatDetail-ranking"></div>

          <div className="boatRent-perNight mt-3 p-0">
            <p>
              {details?.curr_sym}
              {showAmount(details?.boat?.price)} x {days}
            </p>
            <p>
              {details?.curr_sym}
              {showAmount(parseInt(details?.boat?.price) * parseInt(days))}
            </p>
          </div>
          <div className="accomodationRent-perNight mt-2 p-0 ">
            <p>{translate("Discount")}</p>
            <p>
              {showAmount(details?.boat?.discount, 0)}
              {translate("%")}
            </p>
          </div>
          {/* <div className="boatRent-perNight mt-2 p-0 ">
                    <p>{translate("Capri Easy Service")}</p>
                    <p>
                      {translate(details?.curr_sym)}
                      {platform_fee}
                    </p>
                  </div> */}
          <hr />
          <div className="boatRent-perNight-total mt-2 p-0 ">
            <p>{translate("Total Price")}</p>
            <p>
              {details?.curr_sym}
              {showAmount(
                details?.boat?.discount > 0
                  ? parseInt(
                      details?.boat?.price -
                        (details?.boat?.price * details?.boat?.discount) / 100
                    ) *
                      parseInt(days) +
                      parseInt(platform_fee)
                  : parseInt(details?.boat?.price) * parseInt(days) +
                      parseInt(platform_fee),
                0
              )}
            </p>
          </div>

          <div className="boat-detail-button-wrapper">
            
            {(details?.boat?.owner?.boat_inquiry === 1 ||
              details?.boat?.owner?.boat_custom_inquiry === 1) && (
              <>
                {localStorage.getItem("bearerToken") ? (
                  <>
                    <button
                      className="custom-offer-btn"
                      onClick={() => handleInquiry("offer")}
                    >
                      {translate("Wanna Custom Offer, Contact")}
                    </button>
                    <button
                      className="inquriy-send-btn"
                      onClick={() => handleInquiry("inquiry")}
                    >
                      {translate("Send Inquiry")}
                    </button>
                    <p>{translate("You won’t be charged yet")}</p>
                  </>
                ) : (
                  <>
                    <button
                      className="inquriy-send-btn"
                      // onClick={handleOpen}
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      {translate("Book Now")}
                    </button>
                    <p>{translate("You won’t be charged yet")}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoatDetailMobileLeftbar;
