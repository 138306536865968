import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import makeHttpRequest from "../utils/api";
import { useEffect } from "react";
import { useState } from "react";
import { BsFillHeartFill } from "react-icons/bs";
import Toaster from "./Toaster";
import { useSelector } from "react-redux";

/**
 * For captcha v2
 * googlerecaptcha2={'google-recaptcha2'}
 *
 */

function Wishlist({ id, type, style }) {
  const { isLoggedIn } = useSelector((state) => state.user);
  const addWishlist = async (id, type) => {
    try {
      const data = {
        item_id: id,
        item_type: type,
      };
      if (isLoggedIn) {
        const res = await makeHttpRequest("POST", "wishlist", data);
      } else {
        Toaster("error", "Login to add in wishlist ");
      }
    } catch (error) {
      console.error("Error is:", error);
    }
  };

  return (
    <>
      <div
        onClick={(e) => addWishlist(id, type)}
        style={{
          position: "absolute",
          top: "10px",
          right: "25px",
          color: "#FFF",
          background: "#8BC53E",
          padding: "4px 7px",
          borderRadius: "50px",
          cursor: "pointer",
          ...style,
        }}
      >
        <BsFillHeartFill />
      </div>
    </>
  );
}

export default Wishlist;
