import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import makeHttpRequest from "../../utils/api";
import { userLogged } from "../../UserDashboardComponent/UserProfile/userSlice";
import Loader from "../../Components/Loader";
import Toaster from "../../Components/Toaster";
import ExtensionApi from "../../Components/ExtensionApi";
import { useLanguage } from "../../utils/LanguageContext";
import "../../main.css";

function LoginCanvas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Capture previous location
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ username: "", password: "" });
  const { translate } = useLanguage();
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false);
  const from = location.state?.from;

  useEffect(() => {
    // Reset the form state when the component mounts
    setState({ username: "", password: "" });
    setIsCaptchaPassed(false);
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const responseGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      const res = await makeHttpRequest("POST", "auth/google/callback", {
        token: credential,
        user_type: "user",
      });
      setLoggedUser(res);
    } catch (error) {
      console.error(error);
      Toaster("error", "Google login failed");
    }
  };

  const responseGoogleFailure = (error) => {
    console.error(error);
    Toaster("error", "Google login failed");
  };

  const handleLogin = async () => {
    try {
      if (!state.username) {
        Toaster("info", "Enter username");
        return;
      }
      if (!state.password) {
        Toaster("info", "Enter password");
        return;
      }
      setLoading(true);
      const data = {
        username: state.username,
        password: state.password,
        user_type: "user",
      };
      const res = await makeHttpRequest("POST", "login", data);

      setLoading(false);
      if (res.success === true) {
        localStorage.setItem("bearerToken", res.data.token);
        localStorage.setItem("userType", res.data.type);
        localStorage.setItem("userEmail", res.data.user.email);

        Toaster("success", res.message);
        dispatch(userLogged("user"));
        window.reload();
      } else {
        Toaster("error", "Invalid credentials");
      }
    } catch (error) {
      console.error("Error is :", error);
      setLoading(false);
    }
  };

  const setLoggedUser = (res) => {
    if (res?.success) {
      localStorage.setItem("bearerToken", res.data.token);
      localStorage.setItem("userType", res.data.type);
      localStorage.setItem("userEmail", res.data.user?.email);
      dispatch(userLogged(res.data.type === "user" ? "user" : "owner"));
      if (from) {
        navigate(from); // Redirect to the previous page if available
      } else {
        navigate("/"); // If no previous page, go to home (or you can just do nothing)
      }
    } else {
      Toaster("error", "Invalid credentials");
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="login-content-wrapper mt-4 pt-4">
              <div className="login-heading mb-4">
                <img src="/assets/icons/companyLogo.png" alt="..." />
                <div className="SignUpHeading-topIcon">
                  <img src="/assets/icons/aa1.png" alt="..." />
                </div>
                <div className="SignUpHeading-bottomIcon">
                  <img src="/assets/icons/aa2.jpeg" alt="..." />
                </div>
                <h1>{translate("Welcome to CAPRIEASY")}</h1>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleLogin();
                  }}
                >
                  <div className="login-email mt-3">
                    <label>{translate("Email")}</label>
                    <input
                      name="username"
                      type="text"
                      placeholder={translate("Enter User")}
                      ref={usernameRef}
                      value={state.username}
                      onChange={handleInput}
                      onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                      autoComplete="username"
                    />
                  </div>

                  <div className="login-password">
                    <label>{translate("Password")}</label>
                    <input
                      name="password"
                      type="password"
                      placeholder={translate("Enter your password")}
                      ref={passwordRef}
                      value={state.password}
                      onChange={handleInput}
                      onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                      autoComplete="current-password"
                    />
                  </div>
                  <div className="login-password mt-3 mb-3">
                    <ExtensionApi CaptchaPassed={setIsCaptchaPassed} />
                  </div>
                  <div className="remember-wrapper">
                    <div className="form-check remember-me">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {translate("Remember for 30 days")}
                      </label>
                    </div>

                    <Link
                      style={{ fontSize: "16px", color: "#45AAF0" }}
                      to="/forgetPassword"
                    >
                      {translate("Forget Password")}
                    </Link>
                  </div>

                  <div className="signIn-btn">
                    {loading ? (
                      <Loader size={40} />
                    ) : (
                      <button onClick={handleLogin}>{translate("Login In")}</button>
                    )}
                  </div>
                </form>

                <div className="google-login">
                  <GoogleLogin
                    text={translate("Sign in with Google")}
                    onSuccess={responseGoogleSuccess}
                    onError={responseGoogleFailure}
                    cancel_on_tap_outside
                  />
                </div>

                <div className="signUp-link">
                  <p>
                    {translate("Don't have an account?")}{" "}
                    <Link
                      style={{ fontSize: "16px", color: "#45AAF0" }}
                      to="/register"
                    >
                      {translate("Sign Up")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginCanvas;
