import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useLanguage } from "../../utils/LanguageContext";
import SmallImageLoader from "../SmallImageLoader";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const BoatDetailMobileCarousel = ({ images }) => {
  console.log("Images Prop in DetailCarousal:", images); // Log the images prop

  const { data, loading, error } = useSelector((state) => state.landing);
  const { langData, fetchLangData, translate } = useLanguage();
  const [borderIndex, setBorderIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [carouselOpen, setCarouselOpen] = useState(false);

  useEffect(() => {
    // Update screen width when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slidesToShow = screenWidth < 768 ? 1 : 3; // Adjust the breakpoint as needed

  // Determine the number of slides to show based on screen width

  const handleClose = () => setShowModal(false);
  const handleShow = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setCarouselOpen(true);
  };

  const carouselSettings = {
    arrow: true,
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Container>
      {/* Loading and Error Handling */}
      {images && images.length > 0 ? (
        <>
          {screenWidth < 768 ? ( // Check for mobile and tablet view
            <Carousel {...carouselSettings}>
              {images?.map((image, index) => (
                <div key={index} className="image-card hover-effect">
                  <img
                    src={image || "/fallback-image-url.jpg"}
                    alt={` Image ${index}`}
                    onClick={() => {
                      setCurrentIndex(index);
                      setCarouselOpen(true);
                    }}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <Row className="border border p-2 rounded-4">
              <Col sm={6} md={4} lg={3} className="mb-3 p-0">
                <div
                  className="image-card hover-effect"
                  style={{
                    backgroundImage: `url(${
                      images[0] || "/fallback-image-url.jpg"
                    })`,
                    height: "200px",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  <div className="boat-carousel-overlay">
                    <span>See More</span>
                  </div>
                </div>
                <div
                  className="image-card hover-effect mt-2"
                  style={{
                    backgroundImage: ` url(${
                      images[1] || "/fallback-image-url.jpg"
                    }) `,
                    height: "192px",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  <div className="boat-carousel-overlay">
                    <span style={{ color: "#fff", fontSize: "18px" }}>
                      See More
                    </span>
                  </div>
                </div>
              </Col>

              <Col sm={6} md={8} lg={9} className="mb-3">
                <div
                  className="image-card hover-effect"
                  style={{
                    backgroundImage: `url(${
                      images[2] || "/fallback-image-url.jpg"
                    }) `,
                    height: "400px",
                  }}
                  onClick={() => setShowModal(true)}
                >
                  <div className="boat-carousel-overlay">
                    <span>See More</span>
                  </div>
                </div>
              </Col>

              {/* Remaining images */}
              {[3, 4, 5]?.map((index) => (
                <Col key={index} sm={6} md={4} lg={4} className="mb-3 p-0">
                  {images[index] ? (
                    <div
                      className="image-card hover-effect"
                      style={{
                        backgroundImage: `url(${
                          images[index] || "/fallback-image-url.jpg"
                        })`,
                      }}
                      onClick={() => setShowModal(true)}
                    >
                      <div className="boat-carousel-overlay">
                        <span>See More</span>
                      </div>
                    </div>
                  ) : (
                    <div className="no-image-placeholder ml-4">
                      <img
                        src="/assets/icons/image-not-found.webp"
                        alt=""
                        className="image-not-found"
                      />
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          )}
        </>
      ) : (
        <Col sm={12} className="mb-3">
          <div
            className="no-image-placeholder"
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            }}
          >
            <span>No Images Found</span>
          </div>
        </Col>
      )}

      {/* Modal for carousel */}
      <Dialog
        open={carouselOpen}
        onClose={() => setCarouselOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent style={{ position: "relative" }}>
          <IconButton
            onClick={() => setCarouselOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#000",
              zIndex: 9999,
            }}
          >
            <IoCloseCircleOutline style={{ fontSize: "40px" }} />
          </IconButton>
          <Carousel
            index={currentIndex}
            onChange={(index) => setCurrentIndex(index)}
            navButtonsAlwaysVisible
            autoPlay={false}
          >
            {images?.map((image, index) => (
              <img
                key={index}
                src={image || "/fallback-image-url.jpg"}
                alt={` Image ${index}`} // Add alt text for accessibility
                className="boat-detail-carousel-images"
              />
            ))}
          </Carousel>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default BoatDetailMobileCarousel;
