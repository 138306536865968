import React, { useState, useEffect } from "react";
import classes from "./SupportTickets.module.css";
import makeHttpRequest from "../../utils/api";
import calculateTimeDifference from "../../utils/diffForHumans";
import { useLanguage } from "../../utils/LanguageContext";
import NewSupportTickets from "../NewSupportTickets/NewSupportTickets";

function SupportTickets() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { langData, fetchLangData, translate } = useLanguage();
  const [activeTab, setActiveTab] = useState("tickets");
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await makeHttpRequest("GET", "ticket");
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container mt-4">
        <div className="tab-navigation">
          <button
            className={`  ${activeTab === "tickets" ? "active" : ""}  `} // Apply active class if this tab is active
            onClick={() => setActiveTab("tickets")}
          >
            {translate("Tickets")}
          </button>
          <button
            className={` ${activeTab === "pendingTickets" ? "active" : ""}  `} // Apply active class if this tab is active
            onClick={() => setActiveTab("pendingTickets")}
          >
            {translate("Create New Tickets")}
          </button>
        </div>
        {activeTab === "tickets" && (
          <div className={classes["supportTicket-bg"]}>
            <div className={classes["supportTicket-btnWrapper"]}>
              <h2>{translate("Support Ticket")}</h2>
            </div>

            <div className="table-responsive" style={{ borderRadius: "10px" }}>
              <div className={classes["supportTicket-wrapper"]}>
                <table
                  id="mytable"
                  className="table table-bordered table-striped"
                >
                  <thead className="supportTicket-table-th">
                    <tr>
                      <th>{translate("Ticket No.")}</th>
                      <th>{translate("SUBJECT")}</th>
                      <th>{translate("STATUS")}</th>
                      <th>{translate("PRIORITY")}</th>
                      <th>{translate("LAST REPLY")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.supports?.data?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          {translate("#")}
                          {s.ticket}
                        </td>
                        <td>{s.subject}</td>
                        <td>
                          {s.status === 0 && (
                            <span className="badge badge-success">
                              {translate("Open")}
                            </span>
                          )}
                          {s.status === 1 && (
                            <span className="badge badge-primary">
                              {translate("Answered")}
                            </span>
                          )}
                          {s.status === 2 && (
                            <span className="badge badge-warning">
                              {translate("Customer Reply")}
                            </span>
                          )}
                          {s.status === 3 && (
                            <span className="badge badge-dark">
                              {translate("Closed")}
                            </span>
                          )}
                        </td>
                        <td>
                          {s.priority === 1 && (
                            <span className="badge badge-dark">
                              {translate("Low")}
                            </span>
                          )}
                          {s.priority === 2 && (
                            <span className="badge badge-success">
                              {translate("Medium")}
                            </span>
                          )}
                          {s.priority === 3 && (
                            <span className="badge badge-primary">
                              {translate("High")}
                            </span>
                          )}
                        </td>
                        <td>{calculateTimeDifference(s.last_reply)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {activeTab === "pendingTickets" && <NewSupportTickets />}

        {/* <div>

          <div className={classes["supportTicket-bg"]}>
            <div className={classes["supportTicket-btnWrapper"]}>
              <h2>{translate("Support Ticket")}</h2>
              <button className={classes["supportTicket-addBtn"]}>
                <Link to="/new-ticket">{translate("New Ticket")}</Link>
              </button>
            </div>

            <div className="table-responsive" style={{ borderRadius: "10px" }}>
              <div className={classes["supportTicket-wrapper"]}>
                <table
                  id="mytable"
                  className="table table-bordred table-striped"
                >
                  <thead className={classes["supportTicket-table-th"]}>
                    <th>{translate("Ticket No.")}</th>
                    <th>{translate("SUBJECT")}</th>
                    <th>{translate("STATUS")}</th>
                    <th>{translate("PRIORITY")}</th>
                    <th>{translate("LAST REPLY")}</th>
                  </thead>
                  <tbody>
                    {data?.supports?.data?.map((s, i) => (
                      <tr key={i}>
                        <td>
                          {translate("#")}
                          {s.ticket}
                        </td>
                        <td>{s.subject}</td>
                        <td>
                          {s.status === 0 && (
                            <span className="badge badge-success">
                              {translate("Open")}
                            </span>
                          )}
                          {s.status === 1 && (
                            <span className="badge badge-primary">
                              {translate("Answered")}
                            </span>
                          )}
                          {s.status === 2 && (
                            <span className="badge badge-warning">
                              {translate("Customer Reply")}
                            </span>
                          )}
                          {s.status === 3 && (
                            <span className="badge badge-dark">
                              {translate("Closed")}
                            </span>
                          )}
                        </td>
                        <td>
                          {s.priority === 1 && (
                            <span className="badge badge-dark">
                              {translate("Low")}
                            </span>
                          )}
                          {s.priority === 2 && (
                            <span className="badge badge-success">
                              {translate("Medium")}
                            </span>
                          )}
                          {s.priority === 3 && (
                            <span className="badge badge-primary">
                              {translate("High")}
                            </span>
                          )}
                        </td>
                        <td>{calculateTimeDifference(s.last_reply)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default SupportTickets;
